<template>

    <div class="h-auto p-2">

        <div v-if="!smuSelected" class="h-auto w-full rounded-lg module p-2 flex flex-col mt-2 relative">

            <loader :loading="loadingsmu" />

            <div class="h-8 flex flex-row justify-start items-center">
                <span class="text-sm font-semibold text-font-gray">SMU</span>
            </div>

            <div v-if="sl" class="box flex flex-row justify-start items-center mb-2">

                <span class="text-font-gray font-semibold text-xs mr-auto">SL: {{sl}}</span>

                <div class="h-4 w-4 rounded-full bg-body flex flex-col justify-center items-center" @click="setSL(null)">
                    <i class="mdi mdi-close text-xxs text-danger"></i>
                </div>

            </div>

            <div v-if="options.length > 0 && !sl" class="h-6 flex flex-row justify-start items-center px-2">

                <div v-for="(el,index) in options" :key="index" class="h-full w-10 rounded-md flex flex-row justify-center items-center">
                    <span class="text-purple text-xs" :class="getColor(el)">{{el}}</span>
                </div>

            </div>

            <div class="h-auto">

                <div v-for="(el,index) in smuElements" :key="index" class="h-32 box flex flex-row mb-2" @click="selectSMU(el)">

                    <div class="h-full w-1 mr-1">

                        <div class="h-full w-1 rounded-full bg-success"></div>

                    </div>

                    <div class="h-full flex-1 flex flex-col">

                        <div class="h-6 flex flex-row justify-start items-center p-1">

                            <span class="text-xs font-semibold text-font-gray">{{el.name}}</span>

                        </div>

                        <div class="h-6 flex flex-row justify-center items-center">
                            <div class="h-2 w-2 rounded-full bg-purple mr-1"></div>
                            <span class="text-3xs mr-2">Current Chargeability</span>
                            <span class="text-3xs mr-2 font-semibold">{{el.ytd | numberFormat(2)}}%</span>
                            <div class="h-2 w-2 rounded-full bg-green mr-1"></div>
                            <span class="text-3xs mr-2">Y-1 Chargeability</span>
                            <span class="text-3xs font-semibold">{{el.ytd_1 | numberFormat(2)}}%</span>
                        </div>

                        <div class="flex-1 mt-2">

                            <ChartColCompareSmallMobile chartId="chart" :data="[el.mtd, el.mtd_1]"></ChartColCompareSmallMobile>

                        </div>

                    </div>

                </div>

            </div>

        </div>

        <div v-else class="h-auto w-full rounded-lg module p-2 flex flex-col mt-2 relative">

            <loader :loadint="loadCategories" />

            <div class="h-8 flex flex-row justify-start items-center">

                <div class="h-full mr-2 flex flex-col justify-center items-center px-1" @click="smuSelected = false">

                    <i class="mdi mdi-arrow-left text-font-gray text-xs"></i>

                </div>

                <span class="text-sm font-semibold text-font-gray">Categories</span>

            </div>

            <div v-if="options.length > 0" class="h-6 flex flex-row justify-start items-center px-2">

                <div v-for="(el,index) in options" :key="index" class="h-full w-10 rounded-md flex flex-row justify-center items-center">
                    <span class="text-purple text-xs" :class="getColor(el)">{{el}}</span>
                </div>

            </div>


            <div class="h-auto mt-2">

                <div v-for="(el,index) in categories" :key="index" class="h-32 box flex flex-row mb-2" @click="selectSMU(index)">

                    <div class="h-full w-full flex flex-col">

                        <div class="h-6 flex flex-row justify-start items-center p-1">

                            <span class="text-xs font-semibold text-font-gray">{{el.name}}</span>

                        </div>

                        <div class="flex-1 mt-2">

                            <ChartAreaMobile chartId="chart" :data="[el.mtd, el.mtd_1]"></ChartAreaMobile>

                        </div>

                    </div>

                </div>

            </div>

        </div>

    </div>

</template>

<script>
import { TwelveMonthsChart,RevenueDays } from '@/utils';
import { state, actions } from '@/store';

export default {
    props:['sl','options'],
    data(){
        return{
            smuSelected:false,
            loadingsmu:false,
            smuElements:false,
            smus:false,
            loadcat:false,
            categories:false
        }
    },
    methods:{
        getColor(option){

            switch (option) {
                    case 'ASS':
                        return 'text-purple'
                        break;
                    case 'ADV':
                        return 'text-good'
                        break;
                    case 'PAS':
                        return 'text-violet'
                        break;
                    case 'TAX':
                        return 'text-warning'
                        break;
                    case 'TAS':
                        return 'text-green'
                        break;
                }

        },
        setSL(sl){
            // setear la sl
            this.$emit('setSL',sl)
        },
        load(){
            this.loadingsmu = true
            this.axios.get('chargeability/competencyytd', { params: this.params }).then(response => {
                this.loadingsmu = false
                this.smuElements = response.data.data
            })
        },
        loadCategories(){
            this.loadcat = true;
            this.axios.get('chargeability/rankytd', { params: this.params }).then(response => {
                this.loadcat = false
                this.categories = response.data.data
            })
        }
    },
    computed:{
        TwelveMonthsChartCompare(){
            return TwelveMonthsChart(2,false)
        },
        selectSMU(smu){
            this.smuSelected = smu
        },
        params(){
            return state.globalParams
        }
    },
    watch:{
        params(){
            this.load();
        },
        smuSelected(n,o){
            if(n != false){
                this.loadCategories();
            }
        }
    },
    mounted(){
        if(this.params){
            this.load();
        }
    }
}
</script>